/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "components/ServicesRotatingCard";
import RotatingCardFront from "components/ServicesRotatingCard/RotatingCardFront";
import RotatingCardBack from "components/ServicesRotatingCard/RotatingCardBack";
import DefaultInfoCard from "components/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/services-card-front.jpg";
import bgBack from "assets/images/services-card-back.jpg";
import MKTypography from "../../../components/MKTypography";
import React from "react";

function Services() {
  return (
    <MKBox component="section" id="services" py={{ xs: 3, md: 12 }}>
      <Container>
        <MKTypography variant="h3" my={1} color="info">
          Services
        </MKTypography>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Explore Our
                    <br />
                    Services
                  </>
                }
                description="Hired Guns aren't your typical contract software engineers. We're trained to solve problems fast and clean."
              />
              <RotatingCardBack
                image={bgBack}
                title={<>B A N G !</>}
                description="Just like that. You can bring in a Hired Gun to develop a website, write a back-end, deal with deployments, and set you up for long-term success."
                action={{
                  type: "internal", // Keep this unchanged
                  label: "Saddle Up!",
                  onClick: () =>
                    document.getElementById("contact").scrollIntoView({ behavior: "smooth" }), // Smooth scroll to contact
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="horizontal_split"
                  title="Full-Stack Software Development"
                  description="Comprehensive full-stack development services. We do DBs, REST APIs, all the way through the Front-End."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="filter_drama"
                  title="Cloud and DevOps Solutions"
                  description="Specialists in optimizing cloud infrastructures. The focus is on creating streamlined CI/CD automations. AWS experts."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="api"
                  title="API Development and Integration"
                  description="Custom backends and third-party API integrations that work with existing tech. We develop REST and other APIs to spec."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="sign_language"
                  title="Handoff Ready"
                  description="We don't leave you hanging, or suck your budget dry with ongoing maintenance. Handoff prep means YOU have the training and docs to take care of your own systems."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Services;
