import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import RotatingCard from "components/PortfolioRotatingCard";
import RotatingCardFront from "components/PortfolioRotatingCard/RotatingCardFront";
import RotatingCardBack from "components/PortfolioRotatingCard/RotatingCardBack";
import Carousel from "react-material-ui-carousel";
import portfolioItems from "assets/data/portfolioItems.json";

function PortfolioItems() {
  const [itemsWithImages, setItemsWithImages] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      const items = await Promise.all(
        portfolioItems.map(async (item) => {
          try {
            const image = await import(`assets/images/portfolio/${item.image}`);
            return { ...item, image: image.default };
          } catch (err) {
            console.error(`Error loading image for ${item.name}:`, err);
            return { ...item, image: null }; // Handle missing images gracefully
          }
        })
      );
      setItemsWithImages(items);
    };

    loadImages();
  }, []);

  return (
    <Carousel
      fullHeightHover={false}
      indicators={false}
      navButtonsAlwaysVisible={true}
      navButtonsProps={{
        style: {
          opacity: 0.5,
        },
      }}
      navButtonsWrapperProps={{
        style: {
          top: "unset",
          bottom: "-20px",
        },
      }}
    >
      {itemsWithImages.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return isMobile ? (
    <MKBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      mb={4}
      sx={{ minHeight: "550px" }} // Consistent height for entire container
    >
      {/* Image */}
      <MKBox
        component="img"
        src={props.item.image}
        alt={props.item.name}
        width="90%"
        borderRadius="lg"
        mb={2}
      />

      {/* Text Content: Single scrollable container */}
      <MKBox
        sx={{
          width: "90%",
          maxHeight: "400px", // Limit height for text, so it scrolls if too long
          minHeight: "400px",
          overflowY: "auto", // Enable scrolling
        }}
      >
        <MKTypography variant="h5" color="dark" fontWeight="bold" gutterBottom>
          {props.item.name}
        </MKTypography>

        {/* Company Name */}
        <MKTypography variant="body2" color="dark" align="left" gutterBottom>
          <strong>Company:</strong> <br />
          {props.item.company}
        </MKTypography>

        {/* Year */}
        <MKTypography variant="body2" color="dark" align="left" gutterBottom>
          <strong>Year:</strong> {props.item.date}
        </MKTypography>

        {/* Industry */}
        <MKTypography variant="body2" color="dark" align="left" gutterBottom>
          <strong>Industry:</strong> {props.item.industry}
        </MKTypography>

        {/* Technologies */}
        <MKTypography variant="body2" color="dark" align="left" gutterBottom>
          <strong>Technologies:</strong> {props.item.tech.join(", ")}
        </MKTypography>

        <MKBox component="hr" width="100%" my={2} />

        {/* Description */}
        <MKTypography variant="body2" color="dark" align="left">
          {props.item.description}
        </MKTypography>
      </MKBox>
    </MKBox>
  ) : (
    <RotatingCard>
      <RotatingCardFront
        height="100%"
        image={props.item.image}
        color="light"
        title={props.item.name}
      />
      <RotatingCardBack
        image={props.item.image}
        name={props.item.name}
        date={props.item.date}
        company={props.item.company}
        industry={props.item.industry}
        tech={props.item.tech}
        description={props.item.description}
      />
    </RotatingCard>
  );
}

Item.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    date: PropTypes.string,
    company: PropTypes.string,
    tech: PropTypes.arrayOf(PropTypes.string),
    industry: PropTypes.string,
  }).isRequired,
};

function Portfolio() {
  return (
    <MKBox component="section" id="portfolio" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container item xs={12} spacing={0} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={12} sx={{ mx: "0" }}>
            <MKTypography variant="h3" color="info">
              <span>Portfolio</span>
            </MKTypography>
            <PortfolioItems />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Portfolio;
