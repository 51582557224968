/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// HGSPA Globals
import Hamburger from "pages/HGSPA/Hamburger";
import ScrollToTopButton from "pages/HGSPA/ScrollToTopButton";
import SimpleFooter from "pages/HGSPA/Footer";

// HGSPA Sections
import Services from "pages/HGSPA/sections/Services";
import Portfolio from "pages/HGSPA/sections/Portfolio";
import Process from "pages/HGSPA/sections/Process";
import Technologies from "pages/HGSPA/sections/Technologies";
import Contact from "pages/HGSPA/sections/Contact";
import Jazz from "pages/HGSPA/sections/Jazz";

// Images
import bgImage from "assets/images/HiredGunLogo.jpg";
import MKButton from "../../components/MKButton";
import Icon from "@mui/material/Icon";

function HGSPA() {
  return (
    <>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundColor: "#C8BCAF",
          opacity: "1.0",
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          position: "relative",
        }}
      >
        <Container>
          <Grid container spacing={0}>
            <Grid item lg={4} xs={12} mt={{ xs: "5em", md: "0" }}>
              <img
                src={bgImage}
                width="90%"
                style={{ margin: "auto", display: "block" }}
                alt="Hired Gun Logo"
              />
            </Grid>
            <Grid item lg={8} xs={12}>
              <Hamburger />
              <ScrollToTopButton />
              <MKTypography
                mt={3}
                variant="h1"
                color="white"
                textAlign="center"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                When Tech Gets Tough, <br />
                The Hired Gun Rides In.
              </MKTypography>
              <MKBox textAlign="center" mt={1} mb={10}>
                <MKTypography variant="body1" color="white">
                  Tired of consultants who overstay their welcome? At Hired Gun, we&apos;re
                  different. We offer top-tier software expertise without the long-term strings. Our
                  seasoned Wranglers and Marshals deliver custom websites and systems, ensure
                  you&apos;re fully prepared to maintain them, and then ride off into the sunset.
                </MKTypography>

                <MKBox mt={2}>
                  <MKButton
                    color="info"
                    size="large"
                    onClick={() =>
                      document.getElementById("contact").scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    <Icon sx={{ mr: 1 }}>star</Icon>
                    Let&apos;s Saddle Up!
                  </MKButton>
                </MKBox>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Services />
        <Portfolio />
        <Process />
        <Technologies />
        <Contact />
        <Jazz />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <SimpleFooter />
      </MKBox>
    </>
  );
}

export default HGSPA;
