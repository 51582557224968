import React from "react";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import logoCT from "assets/images/logo-ct-dark.png";

function SimpleFooter() {
  const date = new Date().getFullYear();
  return (
    <MKBox component="footer" py={2}>
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <MKBox component="img" src={logoCT} alt="Hired Gun Logo" maxWidth="2rem" mr={1} />
        <MKTypography variant="body2" color="dark">
          Copyright © {date} HGCSD, LLC.
        </MKTypography>
      </Container>
    </MKBox>
  );
}

export default SimpleFooter;
