/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
import PropTypes from "prop-types";

const items = [
  {
    name: "Requirement Gathering",
    icon: "question_answer",
    description:
      "We start by understanding your business needs in depth. Through detailed discussions and analysis, we determine the scope, key objectives, and technical requirements to build a solution that aligns with your goals.",
  },
  {
    name: "Contract",
    icon: "handshake",
    description:
      "After defining the project scope, we formalize the engagement through a clear and transparent contract. This step ensures we’re all on the same page regarding deliverables, timelines, and costs before development begins.",
  },
  {
    name: "Development",
    icon: "code",
    description:
      "Our expert developers get to work, following best practices and agile methodologies to ensure quality, efficiency, and adaptability. We maintain open lines of communication, giving you regular updates and involving you in key decisions throughout the process.",
  },
  {
    name: "Acceptance",
    icon: "favorite",
    description:
      "Once development is complete, we work closely with you to test and validate the solution against the defined requirements. This phase ensures that everything works as expected and meets your standards for functionality and performance.",
  },
  {
    name: "Handoff",
    icon: "school",
    description:
      "This is where Hired Gun sets itself apart. We don’t just hand over the code; we document everything meticulously and train your staff to maintain, update, and troubleshoot the system. We make sure your team has the knowledge and confidence to manage the solution effectively, allowing us to ride off into the sunset knowing you're fully equipped to take care of your system.",
  },
];

function Phase(props) {
  return (
    <MKBox display="flex" alignItems="top" p={2}>
      <MKBox
        height="3rem"
        sx={{
          aspectRatio: 1,
        }}
        variant="gradient"
        bgColor="info"
        color="white"
        coloredShadow="info"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="xl"
      >
        <Icon>{props.item.icon}</Icon>
      </MKBox>
      <MKBox pl={2}>
        <MKTypography variant="h4" my={1}>
          {props.item.name}
        </MKTypography>
        <MKTypography variant="body2" color="text">
          {props.item.description}
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

Phase.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
};

function Process() {
  return (
    <MKBox component="section" id="process" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="top">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1} color="info">
              Our Process
            </MKTypography>
            <MKTypography variant="body" color="text" mb={2}>
              At Hired Gun, we pride ourselves on being the specialists you call when you need fast,
              expert solutions to your software challenges. We come in, assess your needs, build the
              right solution, and ensure your team is fully equipped to manage it before we ride off
              into the sunset. Our mission is to solve your problem, train your team, and leave you
              in control.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              {items.map((item, i) => (
                <Phase key={i} item={item} />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Process;
