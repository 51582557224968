import React, { useState } from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

const accordionData = [
  {
    panel: "panel1",
    title: "Terms and Conditions",
    details: [
      {
        subtitle: "Cookies:",
        text: "We use cookies to improve your experience on our site. These small files help personalize your visit by remembering your preferences. By continuing to use our site, you agree to the use of cookies.",
      },
      {
        subtitle: "Intellectual Property:",
        text: "All content and materials on this website are owned by us or our licensors. You cannot copy, distribute, or reuse it without our permission.",
      },
      {
        subtitle: "Acceptable Use:",
        text: "We expect all users to use our site respectfully. You may not use the site for unlawful, harmful, or abusive purposes.",
      },
      {
        subtitle: "Changes to Terms:",
        text: "We may update these terms from time to time. If major changes occur, we'll notify you.",
      },
    ],
  },
  {
    panel: "panel2",
    title: "Privacy Policy",
    details: [
      {
        subtitle: "Information We Collect:",
        text: "We collect personal details like your name and email address when you provide them to us.",
      },
      {
        subtitle: "How We Use Your Data:",
        text: "The information you share with us is used only to communicate with you and to provide better services.",
      },
      {
        subtitle: "Data Security:",
        text: "We take reasonable steps to protect your personal information from unauthorized access.",
      },
      {
        subtitle: "Your Choices:",
        text: "You can request to delete your personal information anytime by contacting us.",
      },
    ],
  },
  {
    panel: "panel3",
    title: "Become a Hired Gun",
    details: [
      {
        text: "The gig economy and modern remote work place new demands on software engineers and developers. If you think you've got what it takes, send us your resume at Wrangler@HiredGun.dev.",
      },
    ],
  },
];

function Jazz() {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <MKBox component="section" id="jazz" py={{ xs: 3, md: 12 }}>
      <Container>
        {/* Section title */}
        <MKTypography variant="h3" my={1} color="info" textAlign="left">
          The Usual Jazz
        </MKTypography>

        {/* Two-column layout */}
        <Grid container alignItems="flex-start">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="body1" color="text" mb={2}>
              Here you&apos;ll find the important information you need to know, including our Terms
              and Conditions and Privacy Policy.
            </MKTypography>
          </Grid>

          <Grid item xs={12} lg={6} sx={{ ml: { xs: 0, lg: "auto" } }}>
            <Stack sx={{ ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 2 } }}>
              {accordionData.map(({ panel, title, details }) => (
                <Accordion
                  key={panel}
                  expanded={expanded === panel}
                  onChange={handleAccordionChange(panel)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${panel}-content`}
                    id={`${panel}-header`}
                    sx={{ px: 2 }}
                  >
                    <MKTypography variant="h5">{title}</MKTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ px: 2 }}>
                    {details.map((detail, index) => (
                      <div key={index}>
                        {detail.subtitle && (
                          <MKTypography variant="subtitle1" color="dark" gutterBottom>
                            {detail.subtitle}
                          </MKTypography>
                        )}
                        <MKTypography variant="body2" gutterBottom>
                          {detail.text}
                        </MKTypography>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Jazz;
