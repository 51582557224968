import React from "react";
import PropTypes, { string } from "prop-types";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function RotatingCard({ color, image, name, date, company, industry, tech, description }) {
  return (
    <MKBox
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      borderRadius="lg"
      coloredShadow={color}
      position="absolute"
      width="100%"
      height="100%"
      top={0}
      left={0}
      zIndex={5}
      sx={{
        backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
          `${linearGradient(
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85)
          )}, url(${image})`,
        backgroundSize: "cover",
        backfaceVisibility: "hidden",
        transform: "rotateY(180deg)",
        padding: { xs: "1rem", md: "2rem" },
      }}
    >
      {/* Left column */}
      <MKBox
        flex="1"
        pt={{ xs: 4, md: 6 }} // Adjusted padding for mobile
        pb={{ xs: 2, md: 2 }}
        px={{ xs: 2, md: 4 }}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        textAlign="left"
      >
        <MKTypography
          variant="h2"
          color="white"
          gutterBottom
          sx={{
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
            fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem", lg: "3rem" },
            lineHeight: { xs: "1.2", md: "1.5" },
          }}
        >
          {name}
        </MKTypography>
        <MKTypography
          variant="h3"
          color="white"
          gutterBottom
          sx={{
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
            fontSize: { xs: "1.25rem", sm: "1.75rem", md: "2rem", lg: "2.5rem" },
            lineHeight: { xs: "1.2", md: "1.5" },
          }}
        >
          {company}
        </MKTypography>
        <MKTypography
          variant="h4"
          color="white"
          gutterBottom
          sx={{
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
            fontSize: { xs: "1rem", sm: "1.5rem", md: "1.75rem", lg: "2rem" },
            lineHeight: { xs: "1.2", md: "1.5" },
          }}
        >
          {date}
        </MKTypography>
        <MKTypography
          variant="h4"
          color="white"
          gutterBottom
          sx={{
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
            fontSize: { xs: "1rem", sm: "1.5rem", md: "1.75rem", lg: "2rem" },
            lineHeight: { xs: "1.2", md: "1.5" },
          }}
        >
          {industry}
        </MKTypography>
        <MKTypography
          variant="h4"
          color="white"
          gutterBottom
          sx={{
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
            fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem", lg: "1.75rem" },
            lineHeight: { xs: "1.2", md: "1.5" },
          }}
        >
          Technologies:
        </MKTypography>
        <MKTypography
          variant="body"
          color="white"
          sx={{
            textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
            fontSize: { xs: "0.875rem", sm: "1rem", md: "1.25rem", lg: "1.5rem" },
            lineHeight: { xs: "1.3", md: "1.5" },
          }}
        >
          {tech.join(", ")}
        </MKTypography>
      </MKBox>
      {/* Right column - Description */}
      <MKBox
        flex="1"
        pt={{ xs: 4, md: 6 }} // Adjusted padding for mobile
        pb={{ xs: 2, md: 2 }}
        px={{ xs: 2, md: 4 }}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        textAlign="left"
        lineHeight={1.5}
      >
        <MKTypography
          variant="body"
          color="white"
          sx={{
            textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
            fontSize: { xs: "0.875rem", sm: "1rem", md: "1.25rem", lg: "1.5rem" },
            lineHeight: { xs: "1.3", md: "1.5" },
          }}
        >
          <br />
          {description}
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

RotatingCard.defaultProps = {
  color: "info",
};

RotatingCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  industry: PropTypes.string.isRequired,
  tech: PropTypes.arrayOf(string).isRequired,
  date: PropTypes.string.isRequired,
};

export default RotatingCard;
