import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import bgImage from "assets/images/oldwesttelegraph.jpg";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState({ message: "", type: "" });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let formErrors = {};

    if (!formData.name.trim()) {
      formErrors.name = "Full Name is required.";
    }

    if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      formErrors.email = "Valid email is required.";
    }

    if (!formData.message.trim()) {
      formErrors.message = "Please describe your problem.";
    }

    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});

    try {
      const response = await fetch("/cgi-bin/send_email.py", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
      });

      if (response.ok) {
        setSubmitStatus({ message: "Email sent successfully!", type: "success" });
        setFormData({ name: "", email: "", message: "" });
      } else {
        setSubmitStatus({ message: "There was an issue sending your email.", type: "error" });
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setSubmitStatus({ message: "There was an error sending your email.", type: "error" });
    }
  };

  return (
    <MKBox component="section" id="contact" py={{ xs: 3, md: 12 }}>
      <Container>
        <MKTypography variant="h3" my={3} color="info" textAlign="left">
          Contact Us
        </MKTypography>
        <MKBox
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          alignItems="stretch"
          width="100%"
          p={0} // Ensure no extra padding
          m={0} // Ensure no extra margins
        >
          {/* Graphic Box */}
          <MKBox
            display={{ xs: "none", lg: "block" }}
            flex="1"
            borderRadius="xl"
            boxShadow="lg"
            sx={{
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              mr: { lg: 4 },
            }}
          />

          {/* Form Box */}
          <MKBox
            flex="2"
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            p={0} // Remove extra padding
            m={0} // Remove extra margin
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={0} // Align with other sections
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Saddle Up!
              </MKTypography>
            </MKBox>

            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                We&apos;re ready to ride in and take care of the problem, just send us a telegram
                (email us at Wrangler@HiredGun.dev) or use this here little form.
              </MKTypography>
              <MKBox
                width="100%"
                component="form"
                method="post"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="Full Name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="Email"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="How can we help you?"
                      placeholder="Describe your problem..."
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      error={!!errors.message}
                      helperText={errors.message}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info">
                    Send Telegram
                  </MKButton>
                </Grid>

                {/* Show success or error message */}
                {submitStatus.message && (
                  <MKTypography
                    variant="body2"
                    color={submitStatus.type === "success" ? "success" : "error"}
                    align="center"
                    mt={3}
                  >
                    {submitStatus.message}
                  </MKTypography>
                )}
              </MKBox>
            </MKBox>
          </MKBox>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default Contact;
