import React, { useState } from "react";
//import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const HamburgerMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAnchorClick = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
    setAnchorEl(null); // Close the menu after selection
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
    setAnchorEl(null); // Close the menu after selection
  };

  return (
    <div>
      <div
        onClick={handleClick}
        style={{
          position: "fixed",
          top: "20px",
          left: "20px",
          zIndex: 1300,
          cursor: "pointer",
          filter:
            "drop-shadow(1px 1px 0px black) drop-shadow(-1px -1px 0px rgba(0.5, 0.5, 0.5, 0.5)) drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5))", // Add shadow to the bars
        }}
      >
        <MenuIcon fontSize="large" style={{ color: "white" }} />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          mt: "10px",
          "& .MuiPaper-root": {
            backgroundColor: "#C8BCAF",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Drop shadow for the menu
          },
        }}
      >
        <MenuItem onClick={handleScrollToTop}>Home</MenuItem> {/* New Home MenuItem */}
        <MenuItem onClick={() => handleAnchorClick("portfolio")}>Portfolio</MenuItem>
        <MenuItem onClick={() => handleAnchorClick("process")}>Process</MenuItem>
        <MenuItem onClick={() => handleAnchorClick("technologies")}>Technologies</MenuItem>
        <MenuItem onClick={() => handleAnchorClick("contact")}>Contact</MenuItem>
        <MenuItem onClick={() => handleAnchorClick("jazz")}>The Usual Jazz</MenuItem>
      </Menu>
    </div>
  );
};

export default HamburgerMenu;
