import React, { useState, useEffect } from "react";
import UploadIcon from "@mui/icons-material/Upload";

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    visible && (
      <div
        onClick={scrollToTop}
        style={{
          position: "fixed",
          bottom: "40px",
          right: "20px",
          zIndex: 1300,
          cursor: "pointer",
          filter:
            "drop-shadow(1px 1px 0px black) drop-shadow(-1px -1px 0px rgba(0.5, 0.5, 0.5, 0.5)) drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5))", // Same shadow as hamburger
        }}
      >
        <UploadIcon style={{ color: "white", width: "80px", height: "80px" }} />
      </div>
    )
  );
};

export default ScrollToTopButton;
